// @see https://sass-lang.com/documentation/style-rules/placeholder-selectors

%card-default {
  $card-default-border-radius: 20px;

  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: var(--color-white);
  border-radius: 20px;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.07);
  position: relative;
  height: 100%;
  transition: transform $base-transition-duration-slow $base-transition-easing-default;
  will-change: transform;

  @include media($md-down...) {
    padding: 1.5space;
  }

  @include media($md-up...) {
    padding: 2space;
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.25);
    border-radius: $card-default-border-radius;
    transition: opacity $base-transition-duration-slow $base-transition-easing-default;
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
  }

  &.-is-link::after {
    display: block;
    content: "";
    width: 22px;
    height: 16px;
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.10907e-07 7.93747C0.000580629 7.4752 0.387794 7.09208 0.894584 7.02049L1.06758 7.00848L20.6865 7.02721C21.2755 7.02777 21.7524 7.4446 21.7517 7.95822C21.7512 8.42049 21.364 8.80361 20.8572 8.8752L20.6842 8.88721L1.06524 8.86848C0.476281 8.86792 -0.000643854 8.45109 6.10907e-07 7.93747Z' fill='black'/%3E%3Cpath d='M13.029 14.4372L13.1366 14.3084L19.5235 7.92269L13.1898 1.58761C12.8266 1.22442 12.8266 0.63558 13.1898 0.272393C13.5126 -0.0504406 14.0137 -0.086311 14.3762 0.164782L14.505 0.272393L21.4793 7.24677C21.7657 7.53308 21.8263 7.95962 21.6612 8.30529L21.5339 8.52043L21.4262 8.64923L14.4519 15.6236C14.0887 15.9868 13.4998 15.9868 13.1366 15.6236C12.8138 15.3008 12.7779 14.7996 13.029 14.4372Z' fill='black'/%3E%3C/svg%3E%0A");
    mask-repeat: no-repeat;
    mask-position: bottom left;
    background-color: var(--color-blue-base);
    transition: transform $base-transition-duration-slow $base-transition-easing-default;
    will-change: transform;

    @include media($md-down...) {
      padding-top: 31px;
    }

    @include media($md-up...) {
      padding-top: 39px;
    }

    margin-top: auto;
  }

  &.-highlighted {
    background-color: var(--color-turquiose-base);

    > .icon {
      opacity: 0;
    }

    > :is(.title, .copy) {
      color: var(--color-white);
    }

    &::after { background-color: var(--color-white); }
  }

  > .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50px;
    transition: background-color $base-transition-duration-slow $base-transition-easing-default;

    @include media($md-down...) {
      margin-bottom: 1.5space;
    }

    @include media($md-up...) {
      margin-bottom: 2space;
    }

    &.-visible {
      background-color: var(--color-turquiose-base);
    }

    > .svg {
      width: 100%;
      max-width: 21px;
    }
  }

  > .image {
    border-top-left-radius: $card-default-border-radius;
    border-top-right-radius: $card-default-border-radius;

    @include media($md-down...) {
      margin-top: -1.5space;
      margin-left: -1.5space;
      margin-bottom: 21px;
      width: calc(100% + 3space); // stylelint-disable-line
      max-width: calc(100% + 3space); // stylelint-disable-line
    }

    @include media($md-up...) {
      margin-top: -2space;
      margin-left: -2space;
      margin-bottom: 25px;
      width: calc(100% + 4space);
      max-width: calc(100% + 4space);
    }
  }

  > .title {
    color: var(--color-blue-base);

    @include media($md-down...) {
      @include text-lg(medium);
    }

    @include media($md...) {
      @include text-xl(medium);
    }

    @include media($md-up...) {
      @include text-2xl(medium);
    }
  }

  > .copy {
    color: var(--color-blue-3);
    margin-top: 0.5space;

    @include media($md-down...) {
      @include text-base;
    }

    @include media($md-up...) {
      @include text-lg;
    }
  }

  > .actions {
    margin-top: 1.5space;
    color: var(--color-blue-3);
  }

  @media (hover: hover) {
    &.-is-link:hover {
      transform: translateY(-4px);
      transition-duration: $base-transition-duration-fast;

      &::before { opacity: 1; }

      &::after {
        transform: translateX(10px);
        transition-duration: $base-transition-duration-fast;
      }

      > .icon.-visible {
        background-color: var(--color-orange-base);
        transition-duration: $base-transition-duration-fast;
      }
    }
  }
}
