.section-content-image {
  @include container-default-ultra-narrow;

  &:not(._unmargin-top):not(._unmargin-bottom) {
    @include media($sm...) {
      margin-top: 1space;
      margin-bottom: 1space;
    }

    @include media($md...) {
      margin-top: 0;
      margin-bottom: 0;
    }

    @include media($lg...) {
      margin-top: -1space;
      margin-bottom: -1space;
    }

    @include media($xl...) {
      margin-top: -2space;
      margin-bottom: -2space;
    }
  }
}
