// Fonts
$base-font-family: 'Roboto', sans-serif !default;

// Headlines
// @see https://developer.mozilla.org/en/docs/Web/CSS/text-rendering
$base-headline-rendering: optimizeLegibility !default;

// Transitions
$base-transition-easing-default: cubic-bezier(0.3, 0.42, 0.31, 1) !default;
$base-transition-duration-fast: 125ms !default;
$base-transition-duration-default: 250ms !default;
$base-transition-duration-slow: 750ms !default;
$base-transition-default: all $base-transition-duration-default;

// Box
$base-border-radius-default: 0 !default;
