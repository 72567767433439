.form-control {
  @include text-base(normal);

  display: block;
  margin-top: 2space;
  margin-bottom: 2space;
  padding: 12px 24px;
  border-radius: 3space;
  border: 1px solid hsl(199, 20%, 49%);
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.07);
  outline: none;

  &.-error {
    border-color: var(--color-error);
  }

  &:focus {
    border-color: black;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.125);
  }
}
