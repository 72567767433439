.language-picker {
  position: relative;
  color: var(--color-blue-base);

  > .selected {
    @include text-sm(medium);

    cursor: default;
  }

  > .list > .item > .link {
    @include text-sm;

    text-decoration: none;
    color: var(--color-blue-base);
  }

  > .list > .item.-active > .link > :is(.language, .shortcut) {
    @include font-weight(medium);
  }

  @include media("<sm") {
    > .list > .item > .link > .language {
      display: none;
    }
  }

  @include media(">=sm", "<md") {
    > .list > .item > .link > .shortcut {
      display: none;
    }
  }

  @include media("<md") {
    text-align: right;

    > .selected {
      display: none;
    }

    > .list {
      margin: 0;
    }

    > .list > .item {
      display: inline-block;
      padding: 18px;
    }

    > .list > .item.-active .link {
      position: relative;
    }

    > .list > .item.-active .link::after {
      position: absolute;
      display: block;
      content: "";
      width: calc(100% + 32px);
      height: 2px;
      background-color: var(--color-blue-base);
      left: 0;
      top: 100%;
      transform: translateX(-16px);
    }
  }

  @include media(">=md") {
    > .selected {
      display: flex;
      align-items: center;

      &::after {
        display: block;
        content: "";
        width: 12px;
        height: 8px;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.835.994l4.998 4.944L10.69.68' stroke='%23012C3F' stroke-width='2'/%3E%3C/svg%3E");
        margin-left: 6px;
        vertical-align: center;
      }

      > .language { display: none; }
    }

    > .list {
      position: absolute;
      bottom: 0;
      padding: 8px 12px;
      left: 50%;
      margin: 0;
      text-align: center;
      list-style: none;
      transition: $base-transition-default;
      pointer-events: none;
      max-height: 0;
      overflow: hidden;
      transform: translateX(-50%) translateY(100%);
      border-color: transparent;

      > .item {
        padding: 0 12px;

        &.-active {
          border-bottom: 2px solid var(--color-blue-base);
        }

        & + .item {
          margin-top: 0.5space;
        }
      }
    }

    > .list > .item > .link > .shortcut { display: none; }

    &.-open {
      > .selected::after {
        transform: rotate(180deg);
      }

      > .list {
        opacity: 1;
        pointer-events: initial;
        max-height: 300px;
        background-color: var(--color-white);
        border-radius: 6px;
        box-shadow: 0 5px 14px rgba(0, 0, 0, 0.07);
        border: 1px solid var(--color-blue-1);
      }
    }
  }
}
