.content-image {
  > .image {
    max-width: 100%;
  }

  > .caption {
    @include text-base;

    margin-top: 0.5space;
    color: var(--color-blue-3);
  }
}
