.section-newsletter {
  @include container-default-ultra-narrow;

  .section-default-ultra-narrow + & {
    @include media($sm...) {
      margin-top: 6space;
    }

    @include media($md...) {
      margin-top: 8space;
    }

    @include media($lg...) {
      margin-top: 10space;
    }

    @include media($xl...) {
      margin-top: 12space;
    }
  }

  position: relative;

  > .background {
    position: absolute;

    &.-nr1 {
      top: 0;

      @include media("<744px") {
        display: none;
      }

      @include media($md...) {
        left: -5space;
        width: 150px;
      }

      @include media($lg...) {
        left: -6space;
        width: 160px;
      }

      @include media($lg-up...) {
        left: 1.75space;
        width: 195px;
      }
    }

    &.-nr2 {
      @include media($sm...) {
        right: 0;
        bottom: -2space;
        width: 115px;
      }

      @include media($md...) {
        right: 2%;
        bottom: -2space;
        width: 120px;
      }

      @include media($md-up...) {
        right: 5%;
        bottom: 0;
        width: 125px;
      }
    }
  }

  > .container {
    > .title {
      color: var(--color-blue-base);

      @include media($sm...) {
        @include text-2xl(medium);
      }

      @include media($md...) {
        @include text-3xl(medium);
      }

      @include media($md-up...) {
        @include text-4xl(medium);
      }
    }

    > .copy {
      color: var(--color-blue-3);
      margin-top: 3space;

      &.-error {
        margin-top: 3space;
        color: var(--color-error);
      }

      @include media($md-down...) {
        @include text-lg(normal);
      }

      @include media($md-up...) {
        @include text-xl(normal);
      }
    }

    > .message {
      margin-top: 2space;
    }

    > .form {
      margin-top: 3space;

      > .input {
        width: 100%;
        max-width: 576px;
      }
    }

    > .copy.-error + .form {
      margin-top: 1space;

      > .input {
        margin-top: 0;
      }
    }
  }
}
