.section-content-references {
  @include container-default;

  &:not(.-unmargin-top):not(.-unmargin-bottom) {
    @include media($sm...) {
      margin-top: 4space;
      margin-bottom: 4space;
    }

    @include media($md...) {
      margin-top: 5space;
      margin-bottom: 5space;
    }

    @include media($lg...) {
      margin-top: 6space;
      margin-bottom: 6space;
    }

    @include media($xl...) {
      margin-top: 7space;
      margin-bottom: 7space;
    }
  }
}
