.content-text {
  @include text-lg;

  h2 {
    color: var(--color-blue-base);

    @include media($sm...) {
      @include text-3xl(medium);
    }

    @include media($md...) {
      @include text-4xl(medium);
    }

    @include media($md-up...) {
      @include text-5xl(medium);
    }
  }

  h3 {
    color: var(--color-blue-base);

    @include media($sm...) {
      @include text-2xl(medium);
    }

    @include media($md...) {
      @include text-3xl(medium);
    }

    @include media($md-up...) {
      @include text-4xl(medium);
    }
  }

  h4 {
    color: var(--color-blue-base);

    @include media($sm...) {
      @include text-lg(medium);
    }

    @include media($md...) {
      @include text-2xl(medium);
    }

    @include media($lg...) {
      @include text-3xl(medium);
    }

    @include media($xl...) {
      @include text-4xl(medium);
    }
  }

  h5 {
    color: var(--color-blue-base);

    @include media($sm...) {
      @include text-base(medium);
    }

    @include media($md...) {
      @include text-lg(medium);
    }

    @include media($lg...) {
      @include text-2xl(medium);
    }

    @include media($xl...) {
      @include text-3xl(medium);
    }
  }

  label {
    @include text-xs;

    text-transform: uppercase;
  }

  p,
  span,
  li,
  address {
    color: var(--color-blue-base-light);
  }

  blockquote > p {
    @include font-weight(medium);

    display: grid;
    margin: 0;
    grid-template-columns: max-content 1fr;
    align-items: center;
    font-style: italic;
    color: var(--color-blue-base);

    &::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 100%;
      background-color: var(--color-orange-base);
      margin: 0 1space 0 0;
    }
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin-top: 1space;

      &:not([class]) {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: top;

        &::before {
          display: inline-block;
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: var(--color-blue-base-light);
          margin: 0.75space 1space 0 0;
        }
      }
    }

    li > p {
      margin: 0;
    }
  }

  ol {
    padding: 0 0 0 1space;

    li > p {
      margin: 0;
    }
  }

  b,
  strong {
    @include font-weight(medium);

    color: var(--color-blue-base);
  }

  a {
    &:not([class]) {
      color: var(--color-orange-base);
      cursor: cursor;
      text-decoration: underline;
    }
  }

  figcaption {
    margin-top: 1space;
  }

  // Margins
  :is(h2, h3, h4, h5, p) {
    margin-top: 2space;
    margin-bottom: 2space;
  }

  :is(ul, ol, blockquote) {
    margin-top: 3space;
    margin-bottom: 3space;
  }

  :is(h2, h3, h4, h5) {
    margin-top: 4space;
  }
}
