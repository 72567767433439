.header-logo {
  display: grid;
  align-items: center;
  height: 50px;
  width: auto;

  .section-header:is(.-initial):not(.-open) & > .image path {
    .-logo-inverted & {
      fill: white;
    }
  }
}
