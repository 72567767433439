.screenreader-only {
  @include hide-visually;

  &.-focusable {
    &:active,
    &:focus {
      @include unhide-visually;
    }
  }
}
