.section-links {
  @include container-default-narrow;

  > .container {
    > .title {
      color: var(--color-blue-base);

      @include media($sm...) {
        @include text-2xl(medium);
      }

      @include media($md...) {
        @include text-3xl(medium);
      }

      @include media($md-up...) {
        @include text-4xl(medium);
      }
    }

    > .list {
      @include media($sm...) {
        margin-top: 2space;
        margin-bottom: 2space;
      }

      @include media($md...) {
        margin-top: 2.75space;
        margin-bottom: 3space;
      }

      @include media($md-up...) {
        margin-top: 4space;
        margin-bottom: 2.875space;
      }
    }

    > .link {
      @extend .button-primary;
    }
  }
}
