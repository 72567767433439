.styleguide-section {
  > .title {
    color: var(--color-magenta);

    @include media($sm...) {
      @include text-2xl(medium);
    }

    @include media($md...) {
      @include text-3xl(medium);
    }

    @include media($md-up...) {
      @include text-4xl(medium);
    }
  }
}
