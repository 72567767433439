:root {
  --color-black: hsl(0, 0%, 30%);
  --color-white: hsl(0, 0%, 100%);
  --color-green: hsl(74, 54%, 49%);
  --color-gray: hsl(221, 39%, 11%);
  --color-magenta: hsl(330, 93%, 42%);
  --color-turquiose-base: hsl(179, 100%, 26%);
  --color-turquiose-dark: hsl(182, 100%, 19%);
  --color-turquiose-light: hsl(179, 71%, 41%);
  --color-orange-base: hsl(44, 81%, 56%);
  --color-orange-dark: hsl(42, 73%, 52%);
  --color-orange-light: hsl(44, 100%, 64%);
  --color-blue-base: hsl(198, 97%, 13%);
  --color-blue-base-light: hsl(199, 22%, 39%);
  --color-blue-1: hsl(180, 20%, 93%);
  --color-blue-2: hsla(200, 22%, 39%, 0.45);
  --color-blue-3: hsl(200, 22%, 39%);
  --color-teaser-overview-background: hsl(189, 19%, 93%);
  --color-error: hsl(1, 64%, 49%);
}
