.section-default {
  @include container-default;

  + .-content-text {
    @include media($sm...) {
      margin-top: 6space !important;
    }

    @include media($md...) {
      margin-top: 8space !important;
    }

    @include media($lg...) {
      margin-top: 10space !important;
    }

    @include media($xl...) {
      margin-top: 12space !important;
    }
  }
}
