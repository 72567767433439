.title-with-eyebrow {
  width: 100%;

  &.-centered {
    text-align: center;
  }

  > .eyebrow {
    @include text-base;

    display: block;
    color: var(--color-turquiose-dark);
    text-transform: uppercase;
  }

  > .divider {
    @include hide-visually;
  }

  > .title {
    display: block;
    color: var(--color-blue-base);
    margin-bottom: 1space;

    @include media($sm...) {
      @include text-3xl(medium);
    }

    @include media($md...) {
      @include text-4xl(medium);
    }

    @include media($md-up...) {
      @include text-5xl(medium);
    }
  }

  > .eyebrow + .divider + .title {
    margin-top: 0.5space;
  }
}
