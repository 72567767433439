.skip-links {
  list-style: none;
  margin: 0;
  padding: 0;

  > .item {
    margin: 0;
    padding: 0;
  }

  > .item > .link {
    @include hide-visually;

    display: inline-block;
    position: fixed !important;
    top: 1space;
    left: 1space;
    z-index: $z-overlay;
    padding: 0.5space;
    background-color: var(--color-white);
    color: var(--color-black);
    border: 1px solid var(--color-black);
    border-radius: $base-border-radius-default;
    text-decoration: none;

    &:active,
    &:focus {
      @include unhide-visually;
    }
  }
}
