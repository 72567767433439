.section-cta-contact {
  @include container-default;

  > .container {
    display: grid;
    align-items: center;

    @include media($sm...) {
      grid-template-columns: 1fr;
      gap: 1.75space;
    }

    @include media($md...) {
      gap: 3.75space;
    }

    @include media($sm-up...) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media($md-up...) {
      gap: 5.25space;
    }

    > .content {
      position: relative;
      z-index: 3;

      @include media($sm...) {
        order: 2;
      }

      > .title {
        @include media($sm...) {
          @include text-3xl(medium);
        }

        @include media($md...) {
          @include text-4xl(medium);
        }

        @include media($md-up...) {
          @include text-5xl(medium);
        }

        color: var(--color-blue-base);
      }

      > .copy {
        @include media($md-down...) {
          @include text-lg;
        }

        @include media($md-up...) {
          @include text-xl;
        }

        margin-top: 2space;
        color: var(--color-blue-3);
      }
    }

    > .contacts {
      position: relative;

      @include media($sm...) {
        order: 1;
        margin-bottom: 2space;
      }

      > .background {
        position: absolute;
        z-index: 0;

        &.-nr1 {
          display: block;
          left: -4space;

          @include media($sm...) {
            width: 175px;
            bottom: -3space;
          }

          @include media($md...) {
            width: 215px;
            bottom: -5space;
          }

          @include media($md-up...) {
            width: 315px;
            bottom: -5space;
          }
        }

        &.-nr2 {
          display: block;
          top: -3space;
          right: -1space;
          width: 110px;
        }
      }
    }

    > .contacts > .card {
      max-width: 373px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
