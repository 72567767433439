.cookie-banner {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $z-overlay;
  background-color: rgba(#e9f0f0, 0.8);
  padding: 2space $grid-outer-margin;
  backdrop-filter: blur(16px);

  &.-visible {
    display: block;
  }

  > .container {
    display: grid;
    gap: 1space;
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-max-width;
    align-items: end;

    @include media($sm-up) {
      grid-template-columns: auto max-content;
    }

    > .content > .title {
      @include text-base(medium);

      color: var(--color-blue-base);
    }

    > .content > .copy {
      @include text-sm;

      color: #4d6b79;
      margin-top: 0.5space;
    }

    > .actions > .action {
      @extend .button-primary;
    }
  }
}
