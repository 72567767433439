.section-header {
  @include container-default;

  padding-top: 1space;
  padding-bottom: 1space;
  position: fixed;
  top: 0;
  max-width: $page-max-width;
  width: 100%;
  z-index: 100;

  &,
  &::before {
    transition: $base-transition-default;
  }

  &.-inital,
  &.-visible {
    transform: translateY(0%);
  }

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    transition: $base-transition-default;
    z-index: 0;
  }

  &:not(.-initial)::before {
    background-color: rgba(white, 0.9);
    backdrop-filter: blur(16px);
  }

  &.-open::before {
    @include media("<lg") {
      position: absolute;
      display: block;
      content: "";
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      background-color: var(--color-white);
      border-radius: 8px;
      box-shadow: 0 5px 14px rgba(0, 0, 0, 0.25);
    }
  }

  &.-invisible {
    transform: translateY(-100%);
  }
}
