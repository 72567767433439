.button-primary {
  @include button-base;

  background-color: var(--color-orange-base);
  border-radius: 3space;
  color: var(--color-white);

  &:focus {
    border-color: #dea92b;
    background-color: #dea92b;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-orange-light);
      filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.25));
    }
  }
}
