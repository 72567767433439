.contact-card {
  @extend %card-default;

  > .actions {
    line-height: 0;

    > .link {
      + .link {
        margin-left: 1.5space;
      }

      @media (hover: hover) {
        transition: $base-transition-default;

        &:hover {
          transform: translateY(-2px);
          color: var(--color-black);
        }
      }
    }
  }

  > .actions > .link.-mail > .icon {
    width: 24px;
  }

  > .actions > .link.-phone > .icon {
    width: 18px;
  }

  > .actions > .link.-linkedin > .icon {
    width: 20px;
  }
}
