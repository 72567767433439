.call-to-action {
  display: grid;
  grid-gap: 2space;
  justify-content: center;
  align-items: center;

  @include media("<md") {
    grid-template-columns: repeat(1, 1fr);
  }

  @include media(">=md") {
    grid-template-columns: repeat(2, 1fr);
  }

  // Gridonic Theme
  [data-theme="gridonic"] & {
    // ...
  }
}
