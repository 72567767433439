.headernav-item {
  list-style: none;

  > .link {
    @include text-sm;

    color: var(--color-blue-base);
    text-decoration: none;
    white-space: nowrap;
  }

  &.-active > .link {
    @include font-weight(medium);
  }

  @include media($md-up...) {
    margin-right: 16px;
    position: relative;

    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--color-blue-base);
      opacity: 0;
      transition: $base-transition-duration-fast;
    }

    &.-active::after {
      opacity: 1;
    }

    @media (hover: hover) {
      &:hover::after {
        opacity: 1;
      }
    }

    > .link {
      padding-right: 14px;
      padding-left: 14px;
    }
  }

  @include media($md-down...) {
    display: flex;
    justify-content: space-between;

    > .link {
      padding: 16px;
      width: 100%;
    }

    &.-active {
      background-color: var(--color-blue-1);
      border-left: 4px solid var(--color-blue-base);
    }
  }
}
