@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

// Sample with @font-face
//@font-face {
//  font-family: "Font Name";
//  src:
//    url("~@/fonts/FontName.woff") format("woff"),
//    url("~@/fonts/FontName.woff2") format("woff2");
//  font-weight: 400;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: "Font Name";
//  src:
//    url("~@/fonts/FontNameItalic.woff") format("woff"),
//    url("~@/fonts/FontNameItalic.woff2") format("woff2");
//  font-weight: 400;
//  font-style: italic;
//}
