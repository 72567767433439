// Example usage: @include font-weight(regular);
@mixin font-weight($font-weight: regular) { // Default is regular
  $font-weights: (
    thin: 100,
    extra-light: 200,
    ultra-light: 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    demi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900
  );

  $output: $font-weight;

  @if map-has-key($font-weights, $font-weight) {
    $output: map-get($font-weights, $font-weight);
  }

  font-weight: $output;
}
