.teaser-default {
  @include container-default;

  > .container {
    display: grid;
    gap: 2space;

    @include media($sm...) {
      grid-template-columns: 1fr;
    }

    @include media($sm-up...) {
      grid-template-columns: repeat(2, 1fr);
    }

    > .imagewrapper {
      position: relative;
      z-index: 1;
    }

    > .imagewrapper > .image {
      position: relative;
      width: 100%;
      margin: 0 auto;
      z-index: 4;

      @include media($sm...) {
        max-width: 200px;
      }

      @include media($md...) {
        max-width: 280px;
      }

      @include media($md-up...) {
        max-width: 342px;
      }
    }

    > :is(.imagewrapper, .contentwrapper) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  > .container > .contentwrapper > .title > .title {
    margin-bottom: 0;
  }

  > .container > .contentwrapper > .description {
    color: var(--color-blue-3);

    @include media($sm...) {
      @include text-lg;

      margin-top: 1.5space;
    }

    @include media($md...) {
      @include text-lg;

      margin-top: 2space;
    }

    @include media($md-up...) {
      @include text-xl;

      margin-top: 2.5space;
    }
  }

  > .container > .contentwrapper > .action {
    @include media($sm...) {
      margin-top: 2.5space;
    }

    @include media($md...) {
      margin-top: 2space;
    }

    @include media($md-up...) {
      margin-top: 3space;
    }
  }

  > .container > .imagewrapper > .background {
    display: none;
    position: absolute;
    z-index: 0;
  }

  &.-text-right > .container > .imagewrapper {
    > .background {
      &.-nr1 {
        display: block;
        left: -2space;

        @include media($sm...) {
          bottom: -1space;
          width: 175px;
        }

        @include media($md...) {
          bottom: -3space;
          width: 215px;
        }

        @include media($md-up...) {
          bottom: -3space;
          width: 315px;
        }
      }

      &.-nr2 {
        display: block;
        top: -1space;
        right: 0;

        @include media($md-down...) {
          width: 120px;
        }

        @include media($md-up...) {
          width: 167px;
        }
      }

      &.-nr3 {
        display: block;
        top: 20%;
        left: 10%;
        width: 34px;
      }

      &.-nr4 {
        display: block;
        bottom: 3%;
        right: 0;
        width: 100px;
      }
    }
  }

  &.-text-left {
    > .container > .imagewrapper {
      @include media($sm-up...) {
        order: 2;
      }

      > .background {
        &.-nr1 {
          display: block;
          bottom: -3space;
          right: -2space;

          @include media($sm...) {
            width: 175px;
          }

          @include media($md...) {
            width: 215px;
          }

          @include media($md-up...) {
            width: 315px;
          }
        }

        &.-nr3 {
          display: block;
          bottom: 4%;
          left: 10%;
          width: 34px;
        }

        &.-nr4 {
          display: block;
          top: 3%;
          right: -1space;
          width: 100px;
        }
      }
    }
  }
}
