// @see https://necolas.github.io/normalize.css/
// @see https://github.com/csstools/postcss-normalize
@import-normalize;

// @see http://include-media.com/
@import "~include-media/dist/include-media";

// @see https://github.com/unionco/scss-slope-calc
@import "~@union/scss-slope-calc";

// @see https://github.com/jonsuh/hamburgers
$hamburger-types: (slider) !default;
$hamburger-padding-x: 5px !default;
$hamburger-padding-y: 5px !default;
$hamburger-layer-width: 24px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-layer-color: #042130 !default;
$hamburger-layer-border-radius: 10px !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: #042130 !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

@import "~hamburgers/_sass/hamburgers/base";
@import "~hamburgers/_sass/hamburgers/types/slider";
