.content-references {
  display: flex;
  flex: 1 0 0;
  gap: 2space;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  > .title {
    width: 100%;
    color: var(--color-blue-base);
    margin-bottom: 1.5space;

    @include media($sm...) {
      @include text-3xl(medium);
    }

    @include media($md...) {
      @include text-4xl(medium);
    }

    @include media($md-up...) {
      @include text-5xl(medium);
    }
  }

  > .item {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media($sm...) {
      width: calc(50% - 2space);
    }

    @include media($md...) {
      width: calc(33% - 2space);
    }

    @include media($md-up...) {
      width: calc(20% - 2space);
    }
  }

  > .item > .logo {
    width: auto;
  }
}
