:root {
  box-sizing: border-box;
  color: var(--color-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: currentColor;
  display: inline-block;
  text-decoration-skip-ink: auto;
}

img,
svg {
  display: inline-block;
  max-width: 100%;
  height: auto;

  // @see https://stackoverflow.com/a/5804278/4628548
  // vertical-align: if(map-has-key($settings, 'image-align'), map-get($settings, 'image-align'), top);
}

html {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: hsl(180, 20%, 95%);

  &.-mobilenav {
    overflow: hidden;
  }
}

body {
  position: relative;
  max-width: $page-max-width;
  margin: 0 auto;
  background-color: var(--color-white);
  min-height: 100vh;
  -webkit-print-color-adjust: exact !important;
}

// Reset margins
:is(h1, h2, h3, h4, h5, h6, p, blockquote) {
  margin: 0;
}
