.section-teaser-default-43 {
  @include container-default;

  @include media($sm...) {
    padding-top: 3.5space;
    padding-bottom: 3.5space;
  }

  @include media($md...) {
    padding-top: 4.5space;
    padding-bottom: 4.5space;
  }

  @include media($md-up...) {
    padding-top: 8.5space;
    padding-bottom: 8.5space;
  }

  > .container {
    display: grid;

    @include media($sm...) {
      grid-template-columns: 1fr;
      gap: 1.75space;
    }

    @include media($md...) {
      gap: 3.75space;
    }

    @include media($sm-up...) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media($md-up...) {
      gap: 5.25space;
    }

    > .imagewrapper {
      position: relative;
      z-index: 1;
    }

    > :is(.imagewrapper, .contentwrapper) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    > .imagewrapper > :is(.image, .video) {
      width: 100%;
      max-width: 100%;
    }
  }

  > .container > .contentwrapper > .description {
    color: var(--color-blue-3);

    @include media($sm...) {
      @include text-lg;

      margin-top: 1.5space;
    }

    @include media($md...) {
      @include text-lg;

      margin-top: 2space;
    }

    @include media($md-up...) {
      @include text-xl;

      margin-top: 2.5space;
    }
  }

  > .container > .contentwrapper > .action {
    @include media($sm...) {
      margin-top: 2.5space;
    }

    @include media($md...) {
      margin-top: 2space;
    }

    @include media($md-up...) {
      margin-top: 3space;
    }
  }

  &.-text-left {
    > .container > .imagewrapper {
      @include media($sm-up...) {
        order: 2;
      }
    }
  }
}
