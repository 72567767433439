.teaser-overview {
  @include container-default;

  &:not(.-unmargin-top) {
    @include media($sm...) {
      margin-top: 1.5space;
    }

    @include media($md...) {
      margin-top: 3space;
    }

    @include media($md-up...) {
      margin-top: 3space;
    }
  }

  background-color: var(--color-teaser-overview-background);

  > .container {
    display: grid;

    @include media($sm...) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 31px;
      padding-top: 31px;
      padding-bottom: 31px;
    }

    @include media($md...) {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 48px;
      grid-column-gap: 20px;
      padding-top: 68px;
      padding-bottom: 48px;
    }

    @include media($md-up...) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 38px;
      padding-top: 5.5space;
      padding-bottom: 2.5space;
    }

    > .item {
      &:nth-child(2),
      &:nth-child(3n+2) {
        @include media($md-up...) {
          transform: translateY(-48px);
        }
      }

      &:nth-child(1),
      &:nth-child(2n+1) {
        @include media($md...) {
          transform: translateY(-20px);
        }
      }
    }

    > .actions {
      grid-column: 1 / -1;
      text-align: center;
    }
  }
}
