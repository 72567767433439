.content-figures {
  display: grid;
  gap: 2space;
  text-align: center;

  @include media($sm-up...) {
    grid-template-columns: repeat(3, 1fr);
  }

  > .title {
    grid-column: 1 / -1;
    color: var(--color-blue-base);

    @include media($sm...) {
      @include text-2xl(medium);
    }

    @include media($md...) {
      @include text-3xl(medium);
    }

    @include media($lg-up...) {
      @include text-4xl(medium);
    }
  }

  > .item + .item {
    @include media($sm...) {
      margin-top: 1space;
    }
  }

  > .item > .figure {
    color: var(--color-turquiose-base);

    @include media("<md") {
      @include text-5xl(medium);
    }

    @include media(">=md") {
      @include text-7xl(medium);
    }
  }

  > .item > .description {
    @include text-xl;

    color: var(--color-blue-3);

    @include media("<lg") {
      margin-top: 1.5space;
    }

    @include media(">=lg") {
      margin-top: 0.5space;
    }
  }
}
