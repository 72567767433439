$grid-outer-margin: 1.5space !default;
$grid-column-count-small: 6 !default;
$grid-column-count-medium: 12 !default;
$grid-column-count-large: 24 !default;
$grid-column-gap: 1space !default;
$page-max-width: 1900px !default;
$grid-max-width: 1182px !default;

// Sets the breakpoints for the grid.
$grid-breakpoints: map-merge((xs: null), $breakpoints);

@mixin grid-default {
  display: grid;
  column-gap: $grid-column-gap;
  grid-template-columns: repeat(var(--grid-column-count), [column-start] 1fr [column-end]);

  @include media("<md") {
    --grid-column-count: #{$grid-column-count-small};
  }

  @include media(">=md", "<lg") {
    --grid-column-count: #{$grid-column-count-medium};
  }

  @include media(">=lg") {
    --grid-column-count: #{$grid-column-count-large};
  }
}
