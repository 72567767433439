.headernav-list {
  @include media($md-up...) {
    > .back,
    > .previous {
      display: none;
    }
  }

  @include media($md-down...) {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.25s ease-in-out;
    transform: translateX(0);
    visibility: hidden;
    opacity: 0;
    z-index: -1;

    &.-active {
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }

    &.-passed {
      visibility: hidden;
      left: 30px;
      transform: translateX(-30px);
    }
  }
}
