.image-responsive {
  &:not(.-has-caption) {
    line-height: 0;
  }

  > picture,
  > .placeholder {
    > .image {
      width: 100%;
    }
  }

  > .placeholder {
    position: relative;
    display: grid;
    place-items: center;
    background-color: var(--color-blue-2);
    border: 1px solid var(--color-blue-3);

    @include media('<md') {
      padding-top: calc(100% / (var(--aspect-ratio-sm, 1/1)));
    }

    @include media('>=md', '<lg') {
      padding-top: calc(100% / (var(--aspect-ratio-md, 4/3)));
    }

    @include media('>=lg') {
      padding-top: calc(100% / (var(--aspect-ratio-lg, 16/9)));
    }

    > .image {
      fill: var(--color-blue-3);
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  > .caption {
    @include text-xs(regular);

    margin-top: 0.5space;
    color: var(--color-blue-2);
  }
}
