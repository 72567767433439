.styleguide-color-swatch {
  display: grid;
  grid-template-columns: repeat(auto-fit, 84px);

  &.-area {
    grid-template-columns: 1fr;
    grid-template-rows: 400px;
    width: 100%;
  }

  > .swatch {
    position: relative;
    text-align: center;

    &::before {
      content: '';
      display: inline-grid;
      height: 84px;
      width: 84px;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-grid;
      place-items: center;
      height: 84px;
      width: 84px;
      opacity: 0;
      color: white;
      text-shadow: 2px 2px 5px var(--color-black);
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &.-cyan-dark {
      &::before {
        background-color: var(--color-cyan-dark);
      }

      &::after {
        content: '' + #{var(--color-cyan-dark)};
      }
    }

    &.-cyan-base {
      &::before {
        background-color: var(--color-cyan-base);
      }

      &::after {
        content: '' + #{var(--color-cyan-base)};
      }
    }

    &.-cyan-light {
      &::before {
        background-color: var(--color-cyan-light);
      }

      &::after {
        content: '' + #{var(--color-cyan-light)};
      }
    }

    &.-yellow-dark {
      &::before {
        background-color: var(--color-orange-dark);
      }

      &::after {
        content: '' + #{var(--color-orange-dark)};
      }
    }

    &.-yellow-base {
      &::before {
        background-color: var(--color-orange-base);
      }

      &::after {
        content: '' + #{var(--color-orange-base)};
      }
    }

    &.-yellow-light {
      &::before {
        background-color: var(--color-orange-light);
      }

      &::after {
        content: '' + #{var(--color-orange-light)};
      }
    }

    &.-gradient-brand {
      &::before {
        background-image: none;
      }

      &::after {
        content: '180deg, 0% ' + #{var(--color-cyan-base)} + ', 100% ' + #{var(--color-cyan-light)};
      }
    }

    &.-round {
      &::before {
        border-radius: 100%;
      }
    }

    > .overlay {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      display: inline-grid;
      place-items: center;
      height: 84px;
      width: 84px;
      color: var(--color-white);
    }
  }
}
