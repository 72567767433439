// Globally shared font-styles
// ⚠️ Only define what is really really needed.
:root {
  @include text-base;
}

address {
  font-style: normal;
}

button,
input {
  font: inherit;
}
