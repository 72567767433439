.header-hero {
  @include container-default;

  position: relative;

  @include media($sm...) {
    padding-top: 5.5space;
    padding-bottom: 3space;
  }

  @include media($md...) {
    padding-top: 10.5space;
    padding-bottom: 6space;
  }

  @include media($md-up...) {
    padding-top: 13.5space;
    padding-bottom: 8space;
  }

  > .background {
    display: none;
    position: absolute;
    z-index: 0;
  }

  &.-text-right {
    > .background {
      &.-nr1 {
        display: block;
        top: 0;
        left: 0;

        @include media($sm...) {
          width: 70%;
        }

        @include media($sm-up...) {
          width: 50%;
        }
      }

      &.-nr2 {
        display: block;

        @include media($sm...) {
          left: -0.5space;
          top: 46%;
          width: 110px;
        }

        @include media($md...) {
          left: 0;
          top: 80%;
          width: 130px;
        }

        @include media($md-up...) {
          left: 8%;
          bottom: 6space;
          width: 130px;
        }
      }

      &.-nr3 {
        display: block;

        @include media($sm...) {
          right: 1space;
          top: 5space;
          width: 70px;
        }

        @include media($md...) {
          right: 1space;
          top: 8space;
          width: 130px;
        }

        @include media($md-up...) {
          right: 8%;
          top: 10space;
          width: 130px;
        }
      }
    }
  }

  &.-text-left {
    > .container > .imagewrapper {
      @include media($sm-up...) {
        order: 2;
      }
    }

    > .background {
      &.-nr4 {
        display: block;

        @include media($sm...) {
          width: 50%;
          top: 43%;
          right: 1space;
        }

        @include media($sm-up...) {
          bottom: 4space;
          right: 8%;
          width: 20%;
        }
      }

      &.-nr5 {
        display: block;

        @include media($sm...) {
          top: 50%;
          left: 1space;
          width: 30px;
        }

        @include media($md...) {
          bottom: 9space;
          left: 48%;
          width: 40px;
        }

        @include media($md-up...) {
          bottom: 9space;
          left: 55%;
          width: 40px;
        }
      }

      &.-nr6 {
        display: block;

        @include media($sm...) {
          width: 90px;
          top: 4space;
          left: 1space;
        }

        @include media($md...) {
          width: 180px;
          top: 7space;
          left: 48%;
        }

        @include media($md-up...) {
          width: 196px;
          top: 9space;
          left: 55%;
        }
      }

      &.-nr7 {
        display: block;

        @include media($sm...) {
          top: 12space;
          right: 3%;
          width: 40px;
        }

        @include media($md...) {
          top: 18space;
          right: 1space;
          width: 48px;
        }

        @include media($lg...) {
          top: 22space;
          right: 7%;
          width: 48px;
        }

        @include media($xl...) {
          top: 22space;
          right: 13%;
          width: 48px;
        }
      }
    }
  }

  > .container {
    position: relative;
    z-index: 1;
    display: grid;
    gap: 2space;

    @include media($sm...) {
      grid-template-columns: 1fr;
    }

    @include media($sm-up...) {
      grid-template-columns: repeat(2, 1fr);
    }

    > .imagewrapper > .device {
      width: 100%;
      margin: 0 auto;

      @include media($sm...) {
        max-width: 200px;
      }

      @include media($md...) {
        max-width: 280px;
      }

      @include media($md-up...) {
        max-width: 342px;
      }
    }

    > :is(.imagewrapper, .contentwrapper) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  > .container > .contentwrapper > .title {
    color: var(--color-blue-base);

    @include media($sm...) {
      @include text-4xl(medium);
    }

    @include media($md...) {
      @include text-4-5xl(medium);
    }

    @include media($md-up...) {
      @include text-6xl(medium);
    }
  }

  > .container > .contentwrapper > .lead {
    color: var(--color-blue-3);

    @include media($sm...) {
      @include text-base;

      margin-top: 1.5space;
    }

    @include media($md...) {
      @include text-xl;

      margin-top: 2space;
    }

    @include media($md-up...) {
      @include text-3xl-dense();

      margin-top: 3space;
    }
  }

  > .container > .contentwrapper > .action {
    all: unset;

    @include media($sm...) {
      display: none;
    }

    @include media($md...) {
      margin-top: 2space;
    }

    @include media($md-up...) {
      margin-top: 3space;
    }

    > .icon {
      @include media($md-down...) {
        width: 50px;
      }

      @include media($md-up...) {
        width: 70px;
      }
    }
  }
}
