.header-small {
  @include container-default-ultra-narrow;

  position: relative;
  overflow: hidden;

  @include media($sm...) {
    padding-top: 10.125space;
    padding-bottom: 6.125space;
  }

  @include media($sm-up...) {
    padding-top: 13.75space;
    padding-bottom: 8.5space;
  }

  > .background {
    position: absolute;
    z-index: 0;

    &.-nr1 {
      left: 0;

      @include media($sm...) {
        top: -5space;
        width: 300px;
      }

      @include media($md...) {
        top: -3.5space;
        width: 360px;
      }

      @include media($lg...) {
        top: -3space;
        width: 400px;
      }

      @include media($xl...) {
        top: 0;
        width: 470px;
      }

      @include media(">=1550px") {
        top: -3vw;
        width: calc(470px + 12vw);
      }
    }

    &.-nr2 {
      @include media($md-down...) {
        width: 90px;
        left: 0;
        bottom: 0;
      }

      @include media($lg...) {
        width: 120px;
        left: 1space;
        bottom: 1.5space;
      }

      @include media($lg-up...) {
        width: 120px;
        left: 8.25space;
        bottom: 1space;
      }
    }

    &.-nr3 {
      @include media($sm...) {
        right: -6space;
        bottom: 0;
        width: 160px;
      }

      @include media($md...) {
        right: -6space;
        bottom: 4space;
        width: 180px;
      }

      @include media($lg...) {
        right: -5space;
        bottom: 4space;
        width: 200px;
      }

      @include media($xl...) {
        right: 0;
        bottom: 2.25space;
        width: 200px;
      }
    }
  }

  > .container {
    position: relative;
    z-index: 2;
  }

  > .container > .title {
    color: var(--color-blue-base);

    @include media($sm...) {
      @include text-3xl(medium);
    }

    @include media($md...) {
      @include text-4xl(medium);
    }

    @include media($md-up...) {
      @include text-5xl(medium);
    }
  }

  > .container > .lead {
    color: var(--color-blue-3);
    margin-top: 2space;

    @include media($sm...) {
      @include text-lg;
    }

    @include media($sm-up...) {
      @include text-3xl-dense;
    }
  }
}
