.content-benefits {
  display: flex;
  flex: 1 0 0;
  column-gap: 1.5space;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  @include media($sm...) {
    row-gap: 2space;
  }

  @include media($sm-up...) {
    row-gap: 3.25space;
  }

  > .title {
    @include media($sm-up...) {
      margin-bottom: -1.25space;
    }
  }

  > .item {
    line-height: 0;

    @include media($sm...) {
      width: 100%;
    }

    @include media($md...) {
      width: calc(33% - 2space);
    }

    @include media($md-up...) {
      width: 18%;
    }

    > .icon {
      width: 80px;
      margin-bottom: 2space;
    }

    > .title {
      color: var(--color-blue-base);

      @include media($md-down...) {
        @include text-lg(medium);
      }

      @include media($md-up...) {
        @include text-2xl(medium);
      }
    }

    > .description {
      margin-top: 1space;
      color: var(--color-blue-3);

      @include media($md-down...) {
        @include text-base;
      }

      @include media($md-up...) {
        @include text-lg;
      }
    }

    & + .item {
      @include media($sm...) {
        margin-top: 1.625space;
      }
    }
  }
}
