@mixin text-xs($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 12px;
  line-height: 24px;
  text-decoration: none;
}

@mixin text-sm($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 14px;
  line-height: 28px;
  text-decoration: none;
  letter-spacing: 0.573611px;
}

@mixin text-base($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
  letter-spacing: 0.008em;
}

@mixin text-lg($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 18px;
  line-height: 28px;
  text-decoration: none;
  letter-spacing: 0.002em;
}

@mixin text-xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 20px;
  line-height: 28px;
  text-decoration: none;
  letter-spacing: 0.006em;
}

@mixin text-2xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  letter-spacing: 0.4px;
}

@mixin text-3xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 28px;
  line-height: 36px;
  text-decoration: none;
  letter-spacing: 0.004em;
}

@mixin text-3xl-dense($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 28px;
  line-height: 36px;
  text-decoration: none;
  letter-spacing: 0.4px;
}

@mixin text-4xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 36px;
  line-height: 46px;
  text-decoration: none;
  letter-spacing: 0.004em;
}

@mixin text-4-5xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 40px;
  line-height: 50px;
  text-decoration: none;
  letter-spacing: 0.2px;
}

@mixin text-5xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 50px;
  line-height: 60px;
  text-decoration: none;
}

@mixin text-6xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 60px;
  line-height: 74px;
  text-decoration: none;
}

@mixin text-7xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 70px;
  line-height: 76px;
  text-decoration: none;
  letter-spacing: 0.01em;
}

@mixin text-8xl($font-weight: regular) {
  @include font-weight($font-weight);

  font-family: $base-font-family;
  font-size: 96px;
  line-height: 96px;
  text-decoration: none;
}
