.headernav-second {
  @include media($md-up...) {
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    padding: 0;
    background-size: 100% 90%;
    transition: background-size 0.25s ease-in-out;

    &.-active {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      max-width: 1308px;
      background-image: linear-gradient(#dfdfdf, #dfdfdf);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      visibility: visible;
      opacity: 1;
      width: 100%;
      height: auto;
      z-index: 1;
      margin: 0 auto;
      padding: 32px;
    }
  }
}
