.section-header-small {
  margin-top: 0;

  @include media($sm...) {
    margin-bottom: 2space;
  }

  @include media($md...) {
    margin-bottom: 3space;
  }

  @include media($md-up...) {
    margin-bottom: 4space;
  }
}
