@mixin container-spacing {
  &:not(.-unmargin-top) {
    @include media($sm...) {
      margin-top: 3space;
    }

    @include media($md...) {
      margin-top: 4space;
    }

    @include media($lg...) {
      margin-top: 5space;
    }

    @include media($xl...) {
      margin-top: 6space;
    }
  }

  &:not(.-unmargin-bottom) {
    @include media($sm...) {
      margin-bottom: 3space;
    }

    @include media($md...) {
      margin-bottom: 4space;
    }

    @include media($lg...) {
      margin-bottom: 5space;
    }

    @include media($xl...) {
      margin-bottom: 6space;
    }
  }
}

@mixin container-default {
  @include container-spacing;

  padding-left: $grid-outer-margin;
  padding-right: $grid-outer-margin;

  > .container {
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-max-width;
  }
}

@mixin container-default-narrow {
  @include container-default;

  > .container {
    max-width: 880px;
  }
}

@mixin container-default-ultra-narrow {
  @include container-default;

  > .container {
    @include media("<md") {
      max-width: 556px;
    }

    @include media(">=md") {
      max-width: 742px;
    }
  }
}

@mixin fluid-property($property, $min, $max) {
  // @see https://github.com/unionco/scss-slope-calc
  @include slope-calc($property, $min 320px, $max 1440px, clip);

  $fluid-viewport: (320px, 1440px) !default;

  $min-viewport: nth($fluid-viewport, 1);
  $max-viewport: nth($fluid-viewport, 2);

  /* prettier-ignore */
  @if (
    unit($min-viewport) !=
    unit($max-viewport) and
    unit($min-viewport) !=
    unit($min) and
    unit($min-viewport) !=
    unit($max)
  ) {
    /* stylelint-disable-next-line at-rule-no-unknown */
    @debug 'Units for fluid-property() must be equal.';
  }

  /* prettier-ignore */
  @else {
    #{$property}: $min;

    @media (min-width: $min-viewport) {
      $diff: strip-unit($max - $min);
      $diff-vw: strip-unit($max-viewport - $min-viewport);

      #{$property}:
        calc(
          #{$min} + #{$diff} * ((100vw - #{$min-viewport}) / #{$diff-vw})
        );
    }

    @media (min-width: $max-viewport) {
      #{$property}: $max;
    }
  }
}

// In-viewport: hide stuff
@mixin in-viewport-animation-hide($x: 0, $y: 20px) {
  opacity: 0;
  transform: translate($x, $y);
}

// In-viewport: show stuff
@mixin in-viewport-animation-show() {
  opacity: 1;
  transform: translate(0);
  // transition:
  //   opacity $base-transition-duration-slow * 1.5 $base-transition-easing-default,
  //   transform $base-transition-duration-slow $base-transition-easing-default;
}

/*
 * For standard pseudo element attributes.
 * Can be used without any argument: @include pseudo;
 * ...or with arguments: @include pseudo(flex, fixed, 'prefix: ');
 */
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin button-base {
  @include text-base(medium);

  color: currentColor;
  margin: 0;
  background: transparent;
  border-radius: 0;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  text-align: center;
  width: auto;
  cursor: pointer;
  transition: $base-transition-default;
  outline: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    border-color: black;
  }
}

@mixin hide-visually {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
}

@mixin unhide-visually {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
