.headernav-container {
  @include media($md-down...) {
    width: 100%;
    display: none;
    z-index: 1;

    &.-active {
      display: unset;
    }
  }
}
