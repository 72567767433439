.hero-unit {
  @include container-default;
  @include fluid-property(padding-top, 3space, 6space);
  @include fluid-property(padding-bottom, 3space, 6space);

  position: relative;
  background-color: var(--color-white);
  color: var(--color-black);

  > .image {
    mix-blend-mode: screen;
    opacity: 0.5;

    > picture > .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-left: 0;
      margin-right: 0;
      max-width: none;
      z-index: 1;
    }
  }

  > .content {
    position: relative;
    z-index: 2;
  }

  // Animation
  &[data-in-viewport] {
    opacity: 0;
    will-change: opacity;
    transition: 1s opacity ease-in-out;

    &.-in-viewport {
      opacity: 1;
    }
  }
}
