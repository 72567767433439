.header-container {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;

  > .branding {
    width: 100%;
    z-index: 2;
    margin-right: 20px;

    @include media($sm...) {
      max-width: 122px;
    }

    @include media($sm-up...) {
      max-width: 184px;
    }

    @include media("<lg") {
      margin-right: auto;
    }
  }

  > .languages,
  > .navigation {
    @include media(">=sm", "<lg") {
      max-width: 490px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  > .navigation {
    margin-left: auto;
  }

  > .toggle {
    padding-bottom: 0;
    font-size: 0;
    outline: none;

    @include media($md-up...) {
      display: none;
    }
  }

  > .languages {
    @include media(">=md") {
      margin-left: 3space;
    }

    @include media(">=lg") {
      margin-left: 6space;
    }
  }

  @include media($md-down...) {
    flex-wrap: wrap;

    > .navigation {
      width: 100%;
      order: 4;
    }

    > .languages {
      display: none;
      width: 100%;
      order: 3;

      .-mobilenav & {
        display: block;
      }
    }

    > .toggle {
      margin-left: auto;
      order: 2;
      padding-top: 10px;
    }

    //.-mobilenav &::before {
    //  display: block;
    //  content: "";
    //  position: absolute;
    //  pointer-events: none;
    //  top: 10px;
    //  left: 10px;
    //  right: 10px;
    //  bottom: 10px;
    //  background: $palette-white;
    //  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.25);
    //  border-radius: 8px;
    //}
  }
}
