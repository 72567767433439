.link-list {
  display: grid;
  column-gap: 3space;
  padding: 0;
  list-style: none;

  @include media($md-up...) {
    grid-template-columns: repeat(2, 1fr);
  }

  > .item {
    @include text-lg;

    position: relative;
    border-top: 1px solid #c6c6c6;
    border-bottom: 1px solid #c6c6c6;
    margin-bottom: -1px;
    padding: 12px 3space 12px 1space;
    color: var(--color-blue-base);

    @media (hover: hover) {
      &:hover {
        color: var(--color-turquiose-base);
      }
    }

    &::after {
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 1space;
      content: "";
      transform: translateY(-50%);
      background-color: currentColor;
    }

    &:not(.-link)::after {
      width: 19px;
      height: 18px;
      mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.25268 16.0469L17.8989 16.2088C18.2659 16.2123 18.5602 16.4839 18.5562 16.8152C18.5526 17.1134 18.3087 17.3585 17.9925 17.4019L17.8846 17.4087L1.23837 17.2468C0.871414 17.2432 0.577141 16.9717 0.581094 16.6404C0.584651 16.3422 0.828558 16.0971 1.14481 16.0537L1.25268 16.0469Z' fill='%23012E41'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.1665 0.973636C10.1203 0.646675 9.8731 0.39686 9.57487 0.396485C9.24349 0.39607 8.97457 0.703763 8.97421 1.08374L8.96344 12.3711L5.46469 8.87168L5.3816 8.80226C5.14775 8.64026 4.82445 8.6634 4.61617 8.87168C4.38185 9.106 4.38185 9.4859 4.61617 9.72021L9.11576 14.2198L9.19369 14.2849C9.2952 14.3756 9.42282 14.4297 9.56147 14.4299C9.5676 14.4299 9.57371 14.4298 9.5798 14.4296C9.73881 14.4339 9.89922 14.3754 10.0206 14.2541L14.5202 9.75447L14.5896 9.67137C14.7516 9.43753 14.7285 9.11422 14.5202 8.90594C14.2859 8.67163 13.906 8.67163 13.6716 8.90594L10.1634 12.4135L10.1742 1.08524L10.1665 0.973636Z' fill='%23012E41'/%3E%3C/svg%3E%0A");
    }

    &.-link::after {
      width: 15px;
      height: 11px;
      mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.663089 5.07084C0.336128 5.11703 0.0863127 5.36421 0.0859377 5.66244C0.0855228 5.99381 0.393216 6.26273 0.773191 6.2631L12.0605 6.27387L8.56114 9.77261L8.49171 9.85571C8.32971 10.0895 8.35285 10.4129 8.56114 10.6211C8.79545 10.8555 9.17535 10.8555 9.40966 10.6211L13.9093 6.12154L13.9744 6.04362C14.0651 5.9421 14.1191 5.81448 14.1193 5.67583C14.1193 5.6697 14.1192 5.66359 14.119 5.65751C14.1234 5.4985 14.0649 5.33809 13.9435 5.21673L9.44392 0.717129L9.36083 0.647703C9.12698 0.485707 8.80367 0.50885 8.59539 0.717129C8.36108 0.951443 8.36108 1.33134 8.59539 1.56566L12.1029 5.07391L0.774697 5.0631L0.663089 5.07084Z' fill='%23012E41'/%3E%3C/svg%3E%0A");
    }
  }
}
