.section-content {
  @include container-default-narrow;

  > * {
    margin-top: 0;
    margin-bottom: 2space;

    &:nth-child(1) {
      @include text-5xl;
    }

    &:nth-child(2) {
      @include text-xl;

      color: var(--color-black);
    }
  }

  > h1,
  > h2 {
    margin-top: 8space;
  }

  > h3 {
    color: var(--color-blue-base);

    @include media($sm...) {
      @include text-lg(medium);
    }

    @include media($md...) {
      @include text-2xl(medium);
    }

    @include media($lg...) {
      @include text-3xl(medium);
    }

    @include media($xl...) {
      @include text-4xl(medium);
    }
  }

  > figure {
    margin-bottom: 4space;

    > img {
      display: block;
      width: 100%;
    }
  }
}
