.section-footer {
  @include container-default;

  position: relative;
  background: linear-gradient(180deg, #549e44, #13786d);

  > .background {
    margin-left: -$grid-outer-margin;
    width: calc(100% + (2 * #{$grid-outer-margin}));
    max-width: calc(100% + (2 * #{$grid-outer-margin}));
  }

  > .container > :not(.copyright) {
    a {
      text-decoration: none;
    }

    p,
    a {
      @include media($md-down) {
        @include text-base(regular);
      }

      @include media($md-up) {
        @include text-lg(regular);
      }
    }

    p + p {
      margin-top: 8px;
    }

    strong {
      display: inline-block;
      margin-bottom: 13px;

      @include media($md-down) {
        @include text-base(medium);
      }

      @include media($md-up) {
        @include text-xl(medium);
      }
    }
  }

  > .container {
    padding-top: 1space;
    padding-bottom: 3space;
    display: grid;
    gap: 2space;
    color: var(--color-white);

    @include media($sm...) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media($md...) {
      grid-template-columns: 1.75fr 1fr 1fr;
    }

    @include media($md-up...) {
      grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
    }

    > .branding {
      grid-column: 1 / -1;

      > .logo { max-width: 150px; }
    }

    > .address {
      @include media($sm...) {
        grid-column: 1 / -1;
      }
    }

    > .socialmedia {
      @include media($md...) {
        order: 4;
      }
    }

    > .copyright {
      @include text-xs;

      grid-column: 1 / -1;

      @include media($md...) {
        order: 5;
      }

      @include media($md-up...) {
        text-align: right;
      }

      a {
        text-decoration: none;
      }
    }

    > .discover {
      @include media($sm...) {
        grid-column: 1 / -1;
      }

      > .wrapper {
        @include media($md-up...) {
          display: inline-block;
        }
      }

      a:not(:first-child) {
        margin-top: 0.5space;
      }
    }
  }
}
